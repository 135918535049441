import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.isAuthenticated()) {
      return true;  // El usuario está autenticado, permite acceso
    } else {
      this.router.navigate(['/auth/login']);  // Redirige si no está autenticado
      return false;  // Bloquea acceso
    }
  }
}